import React from 'react';
import { Container, Typography, Box, Card, CardContent, Grid } from '@mui/material';

const Shipping = () => {
  return (
    <Container id="shipping" maxWidth="md" style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Shipping Information
      </Typography>
      
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            {/* Delivery Time Section */}
            <Grid item xs={12}>
              <Box>
                <Typography variant="h6" gutterBottom>
                  How long will it take for my order to be delivered?
                </Typography>
                <Typography variant="body1" gutterBottom>
                  An order placed from within Tamilnadu is normally shipped within 1-3 business days. 
                  Post shipping, the normal delivery minimum time is 2-4 working days and maximum time is 7-8 working days.
                  This timeline is subject to change due to both external or internal factors.
                </Typography>
              </Box>
            </Grid>

            {/* Shipping Charges Section */}
            <Grid item xs={12}>
              <Box>
                <Typography variant="h6" gutterBottom>
                  What is the price for the shipping?
                </Typography>
                <Typography variant="body1" gutterBottom>
                  For prepaid orders, there are no shipping charges. However, for products bought with the Cash on Delivery option, 
                  a charge of Rs. 99 to 199 shall be levied on each order. Note: For products on discounts, the shipping charge is 
                  subject to change.
                </Typography>
              </Box>
            </Grid>

            {/* Failed Delivery Section */}
            <Grid item xs={12}>
              <Box>
                <Typography variant="h6" gutterBottom>
                  What if my order doesn’t get delivered?
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Our courier partner will make three attempts to get your delivery to you. If they cannot deliver the product, 
                  then a refund shall be credited to your bank account after verifying the payment details.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Shipping;
