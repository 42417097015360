import React, { useState } from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import { Container, Typography, Button, CardMedia, Grid, Select, MenuItem } from '@mui/material';
import Footer from '../components/SFooter';
import Header from '../components/Header';

const ProductDetails = ({ products }) => {
    const navigate = useNavigate();

  const { productId } = useParams();
  const product = products.find(p => p.id === parseInt(productId));
    
    const [mainImage, setMainImage] = useState(product.img);

  if (!product) {
    return <Typography variant="h6">Product not found</Typography>;
  }

      
  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };
    
    /*<Button variant="outlined" color="secondary" style={{ marginTop: '10px', marginLeft: '10px' }}>Buy Now</Button> */
  return (
       <>
      <Header  />
    <Container style={{ paddingTop: '25px', paddingBottom: '25px' }}>
            <Button variant="contained" color="primary" onClick={handleBackClick}>
        Back
      </Button>
      <Grid container spacing={2}>

        <Grid item xs={12} md={6}>
          <CardMedia
            component="img"
            alt={product.title}
            image={require(`../images/${mainImage}`)}
            title={product.title}
          />
          <Grid container spacing={1} style={{ marginTop: '10px' }}>
            {product.additionalImages.map((img, index) => (
              <Grid item xs={3} key={index}>
                <CardMedia
                  component="img"
                  alt={`${product.title} ${index}`}
                  image={require(`../images/${img}`)}
                  title={`${product.title} ${index}`}
                 onClick={() => setMainImage(img)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">{product.title}</Typography>
          <Typography variant="h5" color="textSecondary">
            ₹{product.price} <span style={{ textDecoration: 'line-through' }}>₹{product.originalPrice}</span> {product.discount} 
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {product.description}
          </Typography>
          <Select defaultValue={product.colors[0]} style={{ marginTop: '10px' }}>
            {product.colors.map((color, index) => (
              <MenuItem key={index} value={color}>{color}</MenuItem>
            ))}
          </Select>
          <Button variant="contained" color="primary" style={{ marginTop: '10px' }}>Buy Now</Button>
         
        </Grid>
      </Grid>
    </Container>
   <Footer />
    </>
  );
};

export default ProductDetails;
