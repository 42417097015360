import React from 'react';
import { Container, Typography, Box, Card, CardContent } from '@mui/material';

const TermsAndConditions = () => {
  return (
    <Container id="terms" maxWidth="md" style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Terms and Conditions
      </Typography>
      <Card>
        <CardContent>
          <Box mb={3}>
            <Typography variant="body1" paragraph>
              <strong>A.</strong> This document is an electronic record in terms of the Information Technology Act, 2000 and rules
              there under as applicable, and the amended provisions pertaining to electronic records in various
              statutes as amended by the Information Technology Act, 2000. This electronic record is
              generated by a computer system and does not require any physical or digital signatures.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>B.</strong> This document is published in accordance with the provisions of Rule 3 (1) of the Information
              Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and
              regulations, privacy policy, and Terms of Use for access or usage of domain name
              [www.ssglobalmart.com] (“Website”), including the related mobile site and mobile application
              (hereinafter referred to as “Platform”).
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>C.</strong> The Platform is owned by SS Global, a company incorporated under the Companies Act, 1956
              with its registered office at NO 85, Ambedkar street, NH1, Maraimalainagar, Tamilnadu,
              Pincode: 601309 (hereinafter referred to as "Platform Owner", “we”, “us”, “our”).
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>D.</strong> Your use of the Platform and tools are governed by the following terms and conditions ("Terms
              of Use") as applicable to the Platform, including the applicable policies which are incorporated
              herein by way of reference. By mere use of the Platform, You shall be contracting with the Platform Owner, and these terms and conditions including the policies
              constitute Your binding obligations with Platform Owner.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>E.</strong> For the purpose of these Terms of Use, "you", “your” or "user"
              shall mean any natural or legal person who has agreed to become a user/buyer on the Platform.
            </Typography>

            <Typography variant="h6" gutterBottom>
              General Terms of Use:
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>I.</strong> To access and use the product, you agree to provide true, accurate, and complete information to
              us during and after registration. You shall be responsible for all acts done through the use of
              your registered account on the Platform.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>II.</strong> Neither we nor any third parties provide any warranty or guarantee as to the accuracy,
              timeliness, performance, completeness, or suitability of the information and materials offered on
              this website. You acknowledge that such information may contain inaccuracies or errors.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>III.</strong> Your use of our product and the Platform is solely and entirely at your own risk.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>IV.</strong> The contents of the Platform are proprietary to us. You will not have any
              authority to claim intellectual property rights over its contents.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>V.</strong> You acknowledge that unauthorized use of the Platform may lead to action against you as per
              these Terms of Use and/or applicable laws.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>VI.</strong> You agree to pay us the charges associated with availing of the Services.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>VII.</strong> You agree not to use the Platform for any unlawful purposes.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>VIII.</strong> You acknowledge that the website may contain links to third-party websites.
              Upon accessing these links, you will be governed by the terms of use and privacy policies of those websites.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>IX.</strong> By initiating a transaction, you are entering into a legally binding contract with the Platform Owner.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>X.</strong> You shall indemnify and hold harmless the Platform Owner, its affiliates, and employees from any third-party claims arising out of your breach of the terms.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>XI.</strong> In no event will the Platform Owner be liable for any indirect, consequential, or punitive damages.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>XII.</strong> Performance under these Terms is subject to a force majeure event.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>XIII.</strong> These Terms are governed by the laws of India.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>XIV.</strong> All disputes will be subject to the exclusive jurisdiction of the courts in Chennai, Tamilnadu.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>XV.</strong> All concerns or communications relating to these Terms must be communicated using the contact information provided on the website.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default TermsAndConditions;
