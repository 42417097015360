import React from 'react';
import { Container, Grid, Card, CardMedia, CardContent, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';



const deals = {
  Electronics: {
    Mobiles: [
      { id: 1000, img: 'lava z70(4949).webp', title: 'lava z 70', price: '₹4949', discount: '' },
      { id: 1001, img: 'micromax-bharat-4.webp', title: 'micromax bharat4', price: '₹4599', discount: '' },
      { id: 1002, img: 'nokia g21.webp', title: 'Nokia G21', price: '₹3999', discount: '' },
      { id: 1003, img: 'ikall k525 pro (4899).webp', title: 'I kallK25', price: '₹4899', discount: '' },
      { id: 1005, img: 'itel a49.jpg', title: 'itel a49', price: '₹4999', discount: '' },
      { id: 1006, img: 'nokia c 20 plus.jpg', title: 'Nokia c20', price: '₹6199', discount: '' },
      { id: 1007, img: 'samsung galaxy a03s.jpg', title: 'Samsung Galaxy A03s', price: '₹7200', discount: '' },
      { id: 1008, img: 'itel vision2s.jpg', title: 'itel vision2s', price: '₹4999', discount: '' },
      { id: 1009, img: 'lava z3pro.jpg', title: 'lava z3 pro', price: '₹6199', discount: '' },
      { id: 1010, img: 'micromax in 2b.jpg', title: 'micromax in 2b', price: '₹6200', discount: '' },
    ],
  },
  Clothing: {
    Women: [
      { id: 2000, img: 'chudidar1.jpeg', title: 'Acuba (1 meter)', price: '₹279', discount: '' },
      { id: 2001, img: 'chudidar 2.jpeg', title: 'chudidar material 2', price: '₹120', discount: '' },
      { id: 2002, img: 'chudidar 3.jpeg', title: 'chudidar material 3', price: '₹150', discount: '' },
      { id: 2003, img: 'no_image.png', title: 'palazo pack of 3', price: '₹550', discount: '' },
      { id: 2004, img: 'top kurti.webp', title: 'top kuruti', price: '₹400', discount: '' },
      { id: 2005, img: 'top kurti 2.webp', title: 'top kuruti1', price: '₹450', discount: '' },
     
    ],
    Men: [
      
      { id: 2006, img: 'jean black.webp', title: 'jeans black', price: '₹350', discount: '' },
      { id: 2007, img: 'jean cargo.webp', title: 'jeans cargo', price: '₹450', discount: '' },
      { id: 2008, img: 'jean cargo.webp', title: 'jeans pack', price: '₹1250', discount: '' },
      { id: 2009, img: 'jean pack 4.webp', title: 'stack jeans pack', price: '₹1500', discount: '' },
      { id: 2010, img: 'jeans-shirt.jpg', title: 'jeans shirts', price: '₹550', discount: '' },
    ],
  }
};

const Deals = ({ selectedCategory, selectedSubCategory }) => {
  const renderDealsByCategory = () => {
    const categoryDeals = deals[selectedCategory] || {};
    return Object.keys(categoryDeals).map((subCategory) => (
      <React.Fragment key={subCategory}>
        <Typography variant="h6" style={{ margin: '20px 0' }}>
          {`${selectedCategory} - ${subCategory}`}
        </Typography>
        <Grid container spacing={4}>
          {categoryDeals[subCategory].map((deal) => (
            <Grid item key={deal.id} xs={12} sm={6} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  alt={deal.title}
                  style={{ objectFit: 'contain' }}
                  height="240" // Adjust height as needed to give more space for the full image
                  image={require(`../images/${deal.img}`)}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6">
                    {deal.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {deal.price} <span>{deal.discount}</span>
                  </Typography>
                  <Button variant="outlined" color="primary" component={Link} to={`/product/${deal.id}`}>
                    View Details
                  </Button>
                  <Button variant="contained" color="primary" style={{ marginLeft: '10px' }} component={Link} to={`/product/${deal.id}`}>
                    Buy Now
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </React.Fragment>
    ));
  };


const renderSubCategoryDeals = () => {
  // Show only items from the selected subcategory
  if (selectedCategory && selectedSubCategory) {
    const categoryDeals = deals[selectedCategory]?.[selectedSubCategory] || [];
    
    return (
      <Grid container spacing={4}>
        {categoryDeals.map((deal) => (
          <Grid item key={deal.id} xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                alt={deal.title}
                height="240"
                image={require(`../images/${deal.img}`)}
                style={{ objectFit: 'contain' }} // Ensures image fits inside the card
              />
              <CardContent>
                <Typography gutterBottom variant="h6">
                  {deal.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {deal.price} <span>{deal.discount}</span>
                </Typography>
                <Button variant="outlined" color="primary" component={Link} to={`/product/${deal.id}`}>
                  View Details
                </Button>
                <Button variant="contained" color="primary" style={{ marginLeft: '10px' }} component={Link} to={`/product/${deal.id}`}>
                  Buy Now
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }

  return null; // Return null if no category or subcategory is selected
};




  const renderAllDeals = () => {
    return Object.keys(deals).map((category) =>
      Object.keys(deals[category]).map((subCategory) => (
        <React.Fragment key={subCategory}>
          <Typography variant="h6" style={{ margin: '20px 0' }}>
            {`${category} - ${subCategory}`}
          </Typography>
          <Grid container spacing={4}>
            {deals[category][subCategory].map((deal) => (
              <Grid item key={deal.id} xs={12} sm={6} md={4}>
                <Card>
                  <CardMedia
                    component="img"
                    alt={deal.title}
                    style={{ objectFit: 'contain' }}
                    height="240" // Adjust height as needed to give more space for the full image
                    image={require(`../images/${deal.img}`)}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6">
                      {deal.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {deal.price} <span>{deal.discount}</span>
                    </Typography>
                    <Button variant="outlined" color="primary" component={Link} to={`/product/${deal.id}`}>
                      View Details
                    </Button>
                    <Button variant="contained" color="primary" style={{ marginLeft: '10px' }} component={Link} to={`/product/${deal.id}`}>
                      Buy Now
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </React.Fragment>
      ))
    );
  };

  return (
    <Container style={{ paddingBottom: '20px' }}>
      <Typography variant="h5" style={{ margin: '20px 0' }}>
        {selectedCategory
          ? `${selectedCategory} ${selectedSubCategory ? `- ${selectedSubCategory}` : ''}`
          : 'All Deals'}
      </Typography>
      {selectedSubCategory ? renderSubCategoryDeals() : (selectedCategory ? renderDealsByCategory() : renderAllDeals())}
    </Container>
  );
};

export default Deals;