import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const Footer = () => {
    
     const linkStyle = { color: 'inherit', textDecoration: 'none' };
    
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: '#003366', // Dark blue color
        padding: '20px 0',
        marginTop: 'auto',
        borderTop: '1px solid #e0e0e0',
        color: '#ffffff', // White text color for contrast
      }}
    >
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
           <Box textAlign="center" mt={4}>
          <Typography variant="body2" color="inherit">
            © {new Date().getFullYear()} SS Global Mart. All rights reserved.
          </Typography>
        </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">Follow Us</Typography>
            <Typography variant="body2">
        <p>WhatsApp : 073050 90539</p>
        <p>Email: shurensangee@gmail.com</p>
            </Typography>
          </Grid>
        </Grid>
      
      </Container>
    </Box>
  );
};

export default Footer;
