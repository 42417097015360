import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const Footer = () => {
    
     const linkStyle = { color: 'inherit', textDecoration: 'none' };
    
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: '#003366', // Dark blue color
        padding: '20px 0',
        marginTop: 'auto',
        borderTop: '1px solid #e0e0e0',
        color: '#ffffff', // White text color for contrast
      }}
    >
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">About Us</Typography>
            <Typography variant="body2" color="inherit">
              We are a leading online marketplace offering a wide range of products at unbeatable prices.
              Our mission is to provide quality products with excellent customer service.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">Quick Links</Typography>
            <Typography variant="body2">
              <Link to="/" style={linkStyle}>Home</Link><br />
              <Link to="/" style={linkStyle}>Products</Link><br />
               <a href="#contactus" style={linkStyle}>
       Contact Us
      </a><br />
           <a href="#refund" style={linkStyle}>
        Refund
      </a><br/>
      <a href="#shipping" style={linkStyle}>
        Shipping Policy
      </a><br/>
      <a href="#terms" style={linkStyle}>
        Terms and Conditions
      </a><br/>
  <a href="#privacy" style={linkStyle}>
        Privacy Policy
      </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">Follow Us</Typography>
            <Typography variant="body2">
        <p>WhatsApp : 073050 90539</p>
        <p>Email: shurensangee@gmail.com</p>
            </Typography>
          </Grid>
        </Grid>
        <Box textAlign="center" mt={4}>
          <Typography variant="body2" color="inherit">
            © {new Date().getFullYear()} SS Global Mart. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
